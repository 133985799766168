import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { loadStripe } from "@stripe/stripe-js"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

let amount = ""
const handleInputChange = event => {
  const target = event.target
  const value = target.value
  amount = value
}

const stripePromise = loadStripe("pk_live_6pq8dHd7AcqIT5AhtAcunBE000dVis1jWV")

const redirectToCheckout = async event => {
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      // Replace with the ID of your price
      { price: amount, quantity: 1 },
    ],
    mode: "payment",
    successUrl: `https://louiseholdenequine.co.uk/thanks`,
    cancelUrl: `https://louiseholdenequine.co.uk/pay`,
  })
  if (error) {
    console.warn("Error:", error)
  }
}

const PayPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  console.log(data)
  return (
    <Layout title={siteTitle} location={data.location}>
      <SEO title="Pay Louise online for your lesson" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Pay Louise
          </h2>
          <div
            className="form-wrap"
            style={{
              width: `90%`,
              margin: `auto`,
              textAlign: `center`,
              maxWidth: `400px`,
            }}
          >
            <form action="#">
              <select onChange={handleInputChange} name="price" id="price">
                <option value="">--Please choose your amount--</option>
                <option value="price_1GsawJGYMUvRP64Z2egOAc46">
                  &pound;10
                </option>
                <option value="price_1GsawKGYMUvRP64ZVPXUOMWb">
                  &pound;15
                </option>
                <option value="price_1GsawJGYMUvRP64ZvCoi7Hqy">
                  &pound;20
                </option>
                <option value="price_1IYyzWGYMUvRP64ZRyWpNpdQ">
                  &pound;22.50
                </option>
                <option value="price_1GsawJGYMUvRP64ZqwH5T91x">
                  &pound;25
                </option>
                <option value="price_1GsawJGYMUvRP64ZMz9pbxJK">
                  &pound;30
                </option>
                <option value="price_1GsawJGYMUvRP64ZCCkL1L7p">
                  &pound;35
                </option>
                <option value="price_1GsawIGYMUvRP64Z7a4a4ZOF">
                  &pound;40
                </option>
                <option value="price_1IYycbGYMUvRP64ZeeQ2z3Iu">
                  &pound;42.50
                </option>
                <option value="price_1GsawKGYMUvRP64ZxTXw2bnz">
                  &pound;45
                </option>
                <option value="price_1GsawJGYMUvRP64ZTMsN8OCc">
                  &pound;50
                </option>
                <option value="price_1HHBIJGYMUvRP64Zo7Efqgm1">
                  &pound;55
                </option>
                <option value="price_1GtBzQGYMUvRP64ZwncBfNZE">
                  &pound;60
                </option>
                <option value="price_1HHBJ6GYMUvRP64ZYLiM7oNC">
                  &pound;65
                </option>
                <option value="price_1HHBJPGYMUvRP64ZDrdolwYf">
                  &pound;70
                </option>
                <option value="price_1HHBKhGYMUvRP64Zvnggkcry">
                  &pound;75
                </option>
                <option value="price_1HHBKwGYMUvRP64Zezpwx5FG">
                  &pound;80
                </option>
                <option value="price_1HHBLBGYMUvRP64ZGJtN8eZf">
                  &pound;85
                </option>
                <option value="price_1HHBLKGYMUvRP64ZIEWWE6ls">
                  &pound;90
                </option>
                <option value="price_1HHBLSGYMUvRP64ZwL5EwQSn">
                  &pound;95
                </option>
                <option value="price_1HHBLaGYMUvRP64ZgVAFUhVw">
                  &pound;100
                </option>
                <option value="price_1HHBLgGYMUvRP64ZJiVoYlrs">
                  &pound;105
                </option>
                <option value="price_1HHBLnGYMUvRP64ZRuGykFMW">
                  &pound;110
                </option>
                <option value="price_1HHBM0GYMUvRP64ZH9kbSe2f">
                  &pound;115
                </option>
                <option value="price_1HHBM6GYMUvRP64ZDyfamXMu">
                  &pound;120
                </option>
                <option value="price_1HHBMCGYMUvRP64ZArVJRzWw">
                  &pound;125
                </option>
                <option value="price_1HHBMIGYMUvRP64Z9Xzb3IM5">
                  &pound;130
                </option>
                <option value="price_1HHBMOGYMUvRP64ZN8n6uHFE">
                  &pound;135
                </option>
                <option value="price_1HHBMUGYMUvRP64ZONbr00E6">
                  &pound;140
                </option>
                <option value="price_1HHBMaGYMUvRP64ZOvPYscfy">
                  &pound;145
                </option>
                <option value="price_1HHBMfGYMUvRP64ZyajZZbTw">
                  &pound;150
                </option>
              </select>
            </form>
            <p style={{ textAlign: `center` }}>
              If you don't see your agreed amount please{" "}
              <Link to={`/contact`}>get in touch</Link>.
            </p>
            <button className="button large pay" onClick={redirectToCheckout}>
              Continue
            </button>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <PayPage location={props.location} data={data} {...props} />
    )}
  />
)
